import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
      useRecaptchaNet
    >
      {element}
    </GoogleReCaptchaProvider>
  );
};

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID, {
      send_page_view: false,
    });
  }
};
