module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","sr"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://www.oxynapse.com","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[],"redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oxynapse","short_name":"Oxynapse","start_url":"/","background_color":"#ffffff","theme_color":"#3dbbd9","display":"standalone","icon":"src/images/oxynapse-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"262976437a540cb057534e98704f61ec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-99Y9913GLQ","cookieName":"oxynapse-consent-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
